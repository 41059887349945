type UUID = string;

class Order {
  readonly id: number;
  readonly orderItems: OrderItem[];
  readonly subtotal: string;
  readonly deliveryFee: string;
  readonly serviceFee: string;
  readonly total: string;
  readonly subscriptionDeliveryFeeSavings: string;
  readonly discount: string;

  constructor(
    id: number,
    orderItems: OrderItem[],
    subtotal: string,
    deliveryFee: string,
    serviceFee: string,
    total: string,
    subscriptionDeliveryFeeSavings: string,
    discount: string
  ) {
    this.id = id;
    this.orderItems = orderItems;

    this.subtotal = subtotal;
    this.deliveryFee = deliveryFee;
    this.serviceFee = serviceFee;
    this.total = total;
    this.subscriptionDeliveryFeeSavings = subscriptionDeliveryFeeSavings;
    this.discount = discount;
  }

  static fromObject(obj: RawOrder) {
    return new Order(
      obj.id,
      obj.order_items.map(o => OrderItem.fromObject(o)),
      obj.subtotal,
      obj.delivery_fee,
      obj.service_fee,
      obj.total,
      obj.subscription_delivery_fee_savings,
      obj.discount
    );
  }
}

class OrderItem {
  readonly id: string;
  readonly menuItemId: number;
  readonly name: string;
  readonly description: string;
  readonly quantity: number;
  readonly totalPrice: string;
  readonly orderOptionSets: OrderOptionSet[];
  readonly mealPreferences: string;

  constructor(id: string, menuItemId: number, name: string, description: string, quantity: number, totalPrice: string, orderOptionSets: OrderOptionSet[], mealPreferences: string) {
    this.id = id;
    this.menuItemId = menuItemId;
    this.name = name;
    this.description = description;
    this.quantity = quantity;
    this.totalPrice = totalPrice;
    this.orderOptionSets = orderOptionSets;
    this.mealPreferences = mealPreferences;
  }

  static fromObject(obj: RawOrderItem) {
    interface TmpOptionSetEl {
      id: number;
      name: string;
      options: any[];
    };

    const rawOptions = obj.options.reduce((acc : TmpOptionSetEl[], option) => {
      let existingOptionSet : TmpOptionSetEl | undefined = acc.find(o => o["id"] == option.option_set_id);

      if (existingOptionSet === undefined) {
        acc.push({ "id": option.option_set_id, "name": option.option_set_name, "options": [] });
      }

      existingOptionSet = acc.find(o => o["id"] == option.option_set_id);

      if (existingOptionSet === undefined) {
        throw "This should be impossible";
      }

      existingOptionSet["options"].push({ id: option.id, name: option.name });

      return acc;
    }, []);

    const optionSets = rawOptions.map(optionSet => {
      const options = optionSet.options.map(o => new OrderOption(o.id, o.name));
      return new OrderOptionSet(optionSet.id, optionSet.name, options);
    });

    return new OrderItem(
      obj.id,
      obj.menu_item_id,
      obj.name,
      obj.description,
      obj.quantity,
      obj.total_price,
      optionSets,
      obj.meal_preferences
    );
  }
}

class OrderOptionSet {
  readonly id: number;
  readonly name: string;

  readonly options: OrderOption[];

  constructor(id: number, name: string, options: OrderOption[]) {
    this.id = id;
    this.name = name;
    this.options = options;
  }
}

class OrderOption {
  readonly id: number;
  readonly name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

interface RawOrder {
  id: number;
  order_items: RawOrderItem[];
  subtotal: string;
  delivery_fee: string;
  service_fee: string;
  total: string;
  subscription_delivery_fee_savings: string;
  discount: string;
}

interface RawOrderItem {
  id: UUID;
  menu_item_id: number;
  name: string;
  description: string;
  quantity: number;
  total_price: string;
  options: RawOrderOption[];
  meal_preferences: string;
}

interface RawOrderOption {
  id: number;
  name: string;
  option_set_id: number;
  option_set_name: string;
}

interface MetricsEventData {
  metricsEvent?: any;
}

export {
  Order, OrderItem, RawOrder, MetricsEventData
};
